<template>
  <div style='position: relative;min-height: 835px;'>
    <div>
      <div class="list1">
        <div class="item">
          <div class="text">当前收益</div>
          <div class="value">{{
              this.getFixed(this.tableList.earning_revenue + this.tableList.cur_cumulative_revenue, 4)
            }}
          </div>
        </div>
        <div class="item">
          <div class="text">当前收益率</div>
          <div class="value">
            {{ this.getPrecent(this.tableList.earning_rate + this.tableList.cur_cumulative_earning_rate, 2) }}
          </div>
        </div>
        <div class="item">
          <div class="text">已实现盈亏</div>
          <div class="value">{{ this.getFixed(this.tableList.cur_cumulative_revenue, 4) }}</div>
        </div>
        <div class="item">
          <div class="text">未实现盈亏</div>
          <div class="value">{{ this.getFixed(this.tableList.earning_revenue, 4) }}</div>
        </div>
        <br/>

        <div class="item">
          <div class="text">最新价格</div>
          <div class="value">{{ this.tableList.ticker_price }}</div>
        </div>
        <div class="item">
          <div class="text">开仓权益</div>
          <div class="value">{{ this.tableList.pos_equity ? this.getFixed(this.tableList.pos_equity, 4) : "-" }}
            {{ this.tableList.margin_type }}
          </div>
        </div>
        <div class="item">
          <div class="text">当前仓位数(持仓量)</div>
          <div class="value">
            {{ this.tableList.per_amount ? Math.abs(this.tableList.position_number) / this.tableList.per_amount : "-" }} ({{
              this.tableList.position_number
            }})
          </div>
        </div>
        <div class="item">
          <div class="text">杠杆倍数</div>
          <div class="value">{{ this.tableList.strategy_leverage }}</div>
        </div>

        <div class="item">
          <div class="text">运行周期</div>
          <div class="value">{{ this.tableList.strategy_cycle }}</div>
        </div>
        <br/>

        <div class="item">
          <div class="text">天线</div>
          <div class="value">{{ getFixed(this.tableList.grid_high_price, 4) }}</div>
        </div>
        <div class="item">
          <div class="text">地线</div>
          <div class="value">{{ getFixed(this.tableList.grid_low_price, 4) }}</div>
        </div>
        <div class="item">
          <div class="text">运行时间</div>
          <div class="value">{{ this.tableList.run_time }}</div>
        </div>
      </div>
      <div class="list2">
        <div class="title">
          <div>买(<span>{{ this.buy_num }}</span>)</div>
          <div>每笔数量：<span>{{ this.tableList.per_amount }}</span> <span class="util"> {{ this.tableList.unit }}</span>
          </div>
          <div>卖(<span>{{ this.sell_num }}</span>)</div>
        </div>
        <div class="main" style="max-height: 300px;overflow-y: auto;">
          <div class="buy">
            <div class="item" v-for="(v,i) in this.tableList.buy_list" :key="i">
              <div class="num">{{ i + 1 }}</div>
              <div class="value">{{ v }}</div>
            </div>
          </div>
          <div class="sell">
            <div class="item" v-for="(v,i) in this.tableList.sell_list" :key="i">
              <div class="num">{{ i + 1 }}</div>
              <div class="value">{{ v }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <VersionNum style="position:absolute;left:0;bottom:0;"></VersionNum>
  </div>

</template>

<script>
import VersionNum from "../../com/version_num";
import InfoCommen from "../../../mixins/InfoCommen";

export default {
  name: "working",
  mixins: [InfoCommen],
  components:{VersionNum},
  props: {
    table: Object,
    fatherMethod: {
      type: Function,
      require: true,
      default: null,
    }
  },
  data() {
    return {
      tableList: {},
      buy_num: 0,
      sell_num: 0
    }
  },
  // watch: {
  //   table(val) {
  //     console.log(val)
  //     debugger
  //     this.tableList = val;
  //     this.buy_num = this.tableList["buy_list"].length
  //     this.sell_num = this.tableList["sell_list"].length
  //   }
  // },
  mounted() {
    if (Object.keys(this.table).length !== 0) {
      this.tableList = this.table
      this.buy_num = this.tableList["buy_list"].length
      this.sell_num = this.tableList["sell_list"].length
      // console.log(this.tableList)
    }
  },
  methods: {
    refresh(data) {
      this.tableList = data
      this.buy_num = this.tableList["buy_list"].length
      this.sell_num = this.tableList["sell_list"].length
    },
    // handlerClose() {
    //   console.log(this.tableList.strategy_pos_id)
    //   this.$confirm('确定要停止'+this.tableList.currency_name+'网格吗?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     updateState({
    //       strategy_pos_id: this.tableList.strategy_pos_id,
    //       state: '停止'
    //     }).then(res => {
    //       console.log(res)
    //       if (res.code == 0) {
    //         this.fatherMethod(this.tableList.strategy_pos_id)
    //         this.$message({
    //           type: 'success',
    //           message: '已成功停止!'
    //         });
    //       }
    //     })
    //   }).catch(() => {
    //     this.$message({
    //       type: 'info',
    //       message: '已取消停止'
    //     });
    //   });
    // }
  }
}
</script>

<style lang="less" scoped>
.list1 {
  .item {
    display: flex;
    justify-content: space-between;
    color: #8b8a8a;
    padding: 3px 5px;

    .value {
      color: lightgray;
    }
  }
}

.chart {
  width: 100%;
  height: 3rem;
  border: 1px solid red;
}

.list2 {
  margin-top: 20px;
  padding: 0 10px;

  .title {
    display: flex;
    justify-content: space-between;
  }

  .main {
    margin: 20px 0px;
    display: flex;
    justify-content: space-between;

    .buy, .sell {
      width: 48%;
      //margin-top: 20px;
      .item {
        display: flex;
        padding: 3px 5px;
        justify-content: space-between;
        margin-top: 3px;
      }
    }

    .buy {
      .item {
        background-color: rgba(0, 128, 0, 0.3);

        .value {
          color: #4bba41;
        }
      }
    }

    .sell {
      .item {
        flex-direction: row-reverse;
        background-color: rgba(165, 42, 42, 0.3);

        .value {
          color: red;
        }
      }
    }
  }
}
</style>
