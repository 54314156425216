<template>
  <div style='position: relative;min-height: 835px;'>
    <div>
      <div class="list1">
        <div class="item">
          <div class="text">24小时配对次数</div>
          <div class="value">{{ this.tableList.matched_num_24h }}</div>
        </div>
        <div class="item">
          <div class="text">总配对次数</div>
          <div class="value">{{ this.tableList.total_matched_num }}</div>
        </div>
        <div class="item">
          <div class="text">日均配对次数</div>
          <div class="value">-</div>
        </div>
        <br/>
        <div class="item">
          <div class="text">总配对利润</div>
          <!--      判断字体颜色-->
          <div class="value">{{ getFixed(this.tableList.total_match_earning,4) }}</div>
        </div>
      </div>
      <div class="log" >
        <div class="item" v-for="(v,i) in this.list" :key="i">
          <p class="time">{{ v.match_time }}</p>
          <div class="title">
            <div class="text">已配对利润</div>
            <div class="value">{{ getFixed(v.match_earning, 4) }}</div>
          </div>
          <div class="main" v-for="(item,i) in v.order_info" :key="i">
            <div class="record">
              <item>
                <div class="text">方向</div>
                <div class="value">
                  <span :class="item.position_side == 'BUY'?'green':'red'">{{ item.position_side }}</span>
                </div>
              </item>
              <item>
                <div class="text">成交均价</div>
                <div class="value">{{ item.position_avg_price }}</div>
              </item>
              <item>
                <div class="text">成交数量</div>
                <div class="value">{{ item.position_number }}</div>
              </item>
              <item>
                <div class="text">成交额</div>
                <div class="value">{{ getFixed(item.position_quote,4) }}</div>
              </item>
              <item>
                <div class="text">手续费</div>
                <div class="value">{{ item.fee }}</div>
              </item>
            </div>
          </div>
        </div>
      </div>
    </div>
    <VersionNum style="position:absolute;left:0;bottom:0;"></VersionNum>
  </div>

</template>

<script>
import InfoCommen from "../../../mixins/InfoCommen";
import VersionNum from "../../com/version_num";
export default {
  name: "finished",
  components:{VersionNum},
  mixins: [InfoCommen],
  props: {
    table: Object
  },
  data() {
    return {
      tableList: {},
      list: {
      }
    }
  },
  mounted() {
    if (Object.keys(this.table).length !== 0) {
      this.tableList = this.table
      this.list = this.table["matched_order"]
      // console.log(this.tableList)
      // console.log(this.list)
    }

    // getGridDetails(this.$route.query.strategy_id,this.$route.query.pos_info[1]).then(res=>{
    //   console.log(res.data)
    //   if(res["code"] == 0){
    //     this.tableList = res.data
    //     this.list = res.data.matched_order
    //   }
    // })
  },
  methods: {
    refresh(data) {
      this.tableList = data
      this.list = this.tableList["matched_order"]
    }
  }
}
</script>

<style lang="less" scoped>
.list1{
  padding-bottom: 20px ;
  .item{
    display: flex;
    justify-content: space-between;
    padding: 3px 5px;
    .value{
      color: lightgray;
    }
    .text{
      font-size: 15px;
    }
  }
}
.log{
  width: 96%;
  margin: 0 auto;
  .item{
    .time{
      color: #fffbe8;
      margin: 0;
      font-size: 18px;
      padding: 5px 0;
    }
    .title{
      display: flex;
      justify-content: space-between;
      .text,.value{
        font-size: 15px;
      }
    }
    .main{
      margin: 0;
      .record{
        padding-bottom: 5px;
        border-bottom: 1px solid lightgray;
      }
      item{
        padding: 3px 0;
        display: flex;
        justify-content: space-between;
        .text,.value{
          font-size: 12px;
        }
      }
    }
  }
}
.text{
  color: #8b8a8a;
}
.value{
  color: #fffbe8;
}
.green{
  color: #4bba41;
}
.red{
  color: #e45353;
}
</style>
