<template>
<div>
  <div class="title">
    <div class="name">{{ this.topFrom.strategy_name }}
<!--      <sapn style="">xxx</sapn>-->
    </div>

    <div class="sub" style="margin-top: 5px">
      <div>运行时间：<span style="color: #fffbe8">{{ this.topFrom.run_time }}</span></div>
      <van-cell
          style="width: 95%;margin:0 auto ;margin-top:10px;height: 40px;margin-top: 10px;border-radius: 10px"
          title="当前交易币对"
          is-link arrow-direction="down"
          :value="this.pos_info[0]"
          @click="showPopup()"
      />
      <van-popup v-model="visible" position="bottom">
        <van-picker
            ref="cellTreePicker"
            show-toolbar
            title="请选择交易币对"
            :defaultIndex="defaultIndex[0]"
            :columns="this.columns"
            @confirm="choosePicker"
            @cancel="closePicker"
        />
      </van-popup>
    </div>
  </div>
  <div class="bottom" >
    <div style="display: flex;justify-content: space-between;margin-top: 20px">
      <div class="title" style="display: flex;margin: 0">
        <p @click="changeVisible" :class="this.editVisible?'bot_color':''">正在执行（<span>{{this.bottomFrom.grid_running_num}}</span>）</p>
        <span style="padding: 0 10px">|</span>
        <p @click="changeUmVisible" :class="this.editVisible?'':'bot_color'">已完成（<span>{{this.bottomFrom.grid_finished_num}}</span>）</p>
        <!--      <span style="padding: 0 10px">|</span>-->
        <!--      <p @click="changeUmVisible" :class="this.editVisible?'':'bule'">已经完成（<span>{{this.bottomFrom.grid_finished_num}}</span>）</p>-->
        <!--    -->
      </div>
      <van-button type="danger" size="mini" style="margin-right: 20px" @click="handlerClose">停止</van-button>
    </div>

    <div class="main" style="margin-top: 20px">
      <!--        <router-view ></router-view>-->
      <working v-if="editVisible" :table="this.bottomFrom" :fatherMethod="stop_currency_pos" :ref="working"/>
      <finished v-else :table="this.bottomFrom" :ref="finished"/>
<!--      <grid_detail v-else :table="this.bottomFrom" :ref="finished"/>-->
    </div>
  </div>
</div>
</template>

<script>
import InfoCommen from "../../../mixins/InfoCommen";
import finished from "./finished";
import working from "./working";
// import grid_detail from "./grid_detail";
import {getGridDetails,getGridDetail,updateState} from "@/api/home";
import { Dialog } from 'vant';
import { Notify } from 'vant';

export default {
  name: "details",
  mixins: [InfoCommen],
  components:{
    finished,working,
    [Dialog.Component.name]: Dialog.Component,
    [Notify.Component.name]: Notify.Component,
  },
  data(){
    return {
      editVisible:true,
      strategy_id:'',
      pos_info:[],
      topFrom:{},
      bottomFrom:{},
      strategy_pos_id: '',
      strategy_pos:[],
      working:"",
      finished:"",
      columns:["aaa","bbbb","cccc"],
      defaultIndex: [],
      visible: false,
      choose: null,
      text: "",
      account_id:''
    }
  },
  watch:{
    strategy_pos_id(val){
      this.strategy_pos_id = val
    }
  },
  async mounted() {
    this.strategy_id = this.$route.query.strategy_id
    this.account_id = this.$route.query.account_id
    this.pos_info = this.$route.query.pos_info
    this.strategy_pos_id = this.pos_info[1]
    // console.log(this.strategy_id)
    this.getTopData(this.strategy_id,this.pos_info[1])

  },
  methods:{
    stop_currency_pos(pos_id){
      let other_pos_info = []
      let other_pos_id = ""
      for (let i in this.topFrom.currency_name_mapping){
        if(this.topFrom.currency_name_mapping[i][1] != pos_id) {
          other_pos_info = this.topFrom.currency_name_mapping[i]
          other_pos_id = this.topFrom.currency_name_mapping[i][1]
          break
        }
      }
      if(other_pos_id == ""){
        this.$router.go(-1)
      }else{
        this.strategy_id = this.$route.query.strategy_id
        this.$route.query.pos_info = other_pos_info
        this.pos_info = other_pos_info
        this.strategy_pos_id = this.pos_info[1]
        this.getTopData(this.strategy_id, this.strategy_pos_id)
      }
    },
    //获取策略数据
    getTopData(strategy_id, strategy_pos_id){
      getGridDetail(strategy_id).then(res=>{
        // console.log(res)
        this.topFrom = res.data
        this.strategy_pos = this.topFrom.currency_name_mapping
        let value = []
        for(let i=0;i<this.strategy_pos.length;i++){
          value.push(this.strategy_pos[i][0])
        }
        // console.log(value)
        this.columns = value
      })
      this.getCurrencyName(strategy_id, strategy_pos_id)
    },
    //获取交易币对的数据
    async getCurrencyName(){
      await getGridDetails(this.strategy_id, this.strategy_pos_id).then(res=>{
        this.bottomFrom = res.data
        this.$refs[this.working].refresh(this.bottomFrom)
      })
    },
    showPopup() {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs.cellTreePicker.setColumnIndex(1, this.defaultIndex[1]);
        this.$forceUpdate();
      });
    },
    async choosePicker() {
      let data = this.$refs.cellTreePicker.getValues();
      // console.log(data)
      for(let i=0;i<this.strategy_pos.length;i++){
        if(data[0] == this.strategy_pos[i][0]){
          this.$router.query = this.strategy_pos[i]
          this.pos_info = this.strategy_pos[i]
          await getGridDetails(this.strategy_id,this.strategy_pos[i][1]).then(res=>{
            this.bottomFrom = res.data
            // console.log(this.bottomFrom)
            this.$refs[this.working].refresh(this.bottomFrom)
          })
        }
      }
      this.choose = data[data.length - 1].code;
      this.text = data[data.length - 1].text;
      this.$emit("input", this.choose);
      this.$forceUpdate();
      this.visible = false;
    },
    closePicker() {
      if (this.clearable) {
        this.choose = null;
        this.text = null;
      }
      this.visible = false;
    },
    //正在执行子组件
    changeVisible(){
      this.editVisible =  true
      this.$refs[this.working].refresh(this.bottomFrom)
    },
    //已完成子组件
    changeUmVisible(){
      this.editVisible = false
      this.$refs[this.finished].refresh(this.bottomFrom)
    },
    //停止
    handlerClose() {
      // console.log(this.strategy_pos_id)
      Dialog.confirm({
        title:'---',
        message: '确定要停止该交易币对吗?',
      }).then(() => {
        // console.log(this.strategy_id,this.strategy_pos_id)
        updateState({
              list_strategy_id:[this.strategy_id],
              state: '停止',
              strategy_pos_id: this.strategy_pos_id
            }).then(res => {
              // console.log(res)
              if (res.code == 0) {
                Notify({ type: 'primary', message: '已停止该交易币对' });
              }
              else {
                Notify({ type: 'danger', message: '停止失败' });
              }
            })
          })
          .catch(() => {
            // on cancel
          });

    },
  },
  beforeRouteUpdate(to, from, next){
    // console.log(to,from)
    if(to.path=== "/home"){
      next(vm=>{
        vm.newPath = this.account_id
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title{
  margin-top: 20px;
  .name{
    font-size: 1.5rem;
    sapn{
      display: inline-block;
      color: rgb(	255,215,0);
      background-color:rgba(255,255,0,0.3);
      margin-left: 10px;
      font-size: 1.3rem;
      padding: 0 3px;
      border-radius: 5px;
    }
  }
  .sub{
    color: #8b8a8a;
  }
}
.van-cell__title{
  color: #dddddd;
}
.bottom{
  .title{
    p{
      margin: 0;
      padding-bottom: 5px;
    }
  }
}
.bot_color{
  border-bottom: 2px solid rgb(	255,215,0);
}
</style>
